<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      :title="'Курс: ' + dataSource.Name"
      :isUnsaved="isUnsaved && valid"
      @onSave="save"
      @onRefresh="init"
      :show-save="!readonly || isCurator"
      id="knowledge-base__courses-edit"
    >
      <template slot="subTitle" v-if="dataSource.Status && !isEmployee">
        <span
          class="ml-5 knowledge-base__status-point"
          :class="
            'knowledge-base__status-point--' +
            [0, 1, 2, 4, 3][dataSource.Status]
          "
        ></span>
        <span class="caption"
          >Статус: {{ CourseStatus[dataSource.Status - 1].value }}</span
        >
      </template>
      <report-activator
        v-if="!isEmployee && !isNested && courseType !== 'base'"
        slot="action"
        report="CourseReport"
        :params="{
          objectId: { name: 'pCourse', value: $route.params.objectId },
        }"
      />
      <v-btn
        v-if="courseType === 'base' && dataSource.Id > 0"
        slot="action"
        dark
        color="blue"
        title="Создать учебный курс"
        class="ml-4"
        @click="applyCourse"
      >
        Создать учебный курс
      </v-btn>
      <v-form class="block__content" :readonly="readonly" v-model="valid">
        <v-text-field
          v-if="isNested"
          :value="$options.filters.PersonShortName(dataSource.Employee)"
          label="Сотрудник"
          class="mt-4 mx-5"
        ></v-text-field>
        <v-container v-if="!isNested">
          <v-row>
            <v-col>
              <v-text-field
                v-model="dataSource.Name"
                label="Название курса"
                maxlength="200"
                :required="!isEmployee"
                autofocus
                :rules="[rules.required]"
                height="42"
                v-up-first-symbol
              ></v-text-field>
              <v-textarea
                v-model="dataSource.Description"
                min-height="42"
                label="Описание"
                maxlength="200"
                background-color="transparent"
                rows="1"
                auto-grow
                v-up-first-symbol
              ></v-textarea>
              <v-checkbox
                v-if="courseType !== 'base' && !this.readonly"
                class="mt-5 && pb-5"
                v-model="dataSource.IsLimited"
                hide-details
                :label="'Установить период прохождения курса'"
              ></v-checkbox>
              <v-row v-if="dataSource.IsLimited && courseType !== 'base'">
                <v-col>
                  <base-date-picker
                    v-model="dataSource.Period.DateIn"
                    label="Дата начала обучения"
                    :required="!isEmployee"
                    :readonly="readonly"
                    :rules="[rules.required]"
                  ></base-date-picker>
                </v-col>
                <v-col>
                  <base-date-picker
                    v-model="dataSource.Period.DateOut"
                    label="Дата завершения обучения"
                    :readonly="readonly"
                  ></base-date-picker>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <m-autocomplete
                v-model="dataSource.Subject"
                title="Тематика курса. Объединяет курсы по определенной теме или направлению"
                label="Тематика"
                chips
                :items="categories"
                item-text="Name"
                return-object
                :readonly="readonly"
                :clearable="!readonly"
                dict="СourseCategories"
              ></m-autocomplete>
              <m-autocomplete
                v-model="dataSource.Authors"
                title="Автор курса"
                label="Автор"
                chips
                :items="employees"
                :readonly="readonly"
                :clearable="!readonly"
                multiple
                filter="EmployeeActive"
                dict="Employees"
                :textCustomAdd="'Создать нового сотрудника'"
                return-object
              ></m-autocomplete>
              <m-autocomplete
                v-if="courseType !== 'base'"
                multiple
                v-model="dataSource.Curators"
                label="Кураторы"
                return-object
                filter="EmployeeActive"
                dict="Employees"
                :textCustomAdd="'Создать нового сотрудника'"
              ></m-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="courseType === 'base'">
            <v-col lg="6">
              <document-select-emlpoyees
                label="Рекомендовано для"
                v-model="dataSource.Recommendations"
                hideItem="employee"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <base-panel class="mt-2" headerHidden>
        <m-tabs
          v-if="courseType !== 'base' && !isEmployee"
          v-model="tab"
          v-show="!isNested"
        >
          <v-tab key="Lessons">Учебные материалы</v-tab>
          <v-tab key="Participants">Сотрудники</v-tab>
        </m-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Lessons" class="mt-3">
            <records
              @addLesson="addLesson"
              @addMaterial="addMaterial"
              @deleteLesson="deleteLesson"
              @editLesson="editLesson"
              @copyNewLessons="copyNewLessons"
              @deleteMaterial="deleteMaterial"
              :employees="dataSource.Employees"
              :value="dataSource.Records"
              :readonly="readonly"
              :isEmployee="isEmployee"
              :isNested="isNested"
              @refresh="init"
              :type="type"
              :isCurator="isCurator"
            />
          </v-tab-item>
          <v-tab-item key="Participants" class="mt-3">
            <participants
              v-model="dataSource.Employees"
              :isCurator="isCurator"
              :readonly="!(!readonly || isAuthor || isCurator)"
            />
          </v-tab-item>
        </v-tabs-items>
      </base-panel>
      <lesson-edit
        v-model="dialogEdit"
        :lesson="currentLesson"
        :readonly="readonly"
        :width="'80vw'"
        :height="'80vh'"
        @save="saveLesson"
      ></lesson-edit>
    </base-detail-view>
  </v-layout>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Permissions from "@/data/permissions";
import mAutocomplete from "@/components/base/inputs/mAutocomplete";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import KnowledgeBaseService from "@/services/KnowledgeBaseService";
import { KnowledgeBase } from "../../data/defaultObjects";
import records from "./components/records.vue";
// import MCombobox from "../../components/base/inputs/mCombobox.vue";
// import SelectEmployees from "../../components/organization/employees/SelectEmployees.vue";
import participants from "./components/participants";
import LessonEdit from "./LessonEdit.vue";
import ReportActivator from "../../components/report/ReportActivator.vue";
import BaseDatePicker from "@/components/base/BaseDatePicker";
import DataHelper from "../../utils/DataHelper";
import { CourseStatus } from "../../data/enums";
import DocumentSelectEmlpoyees from "@/views/document/components/DocumentSelectEmlpoyees.vue";
import mTabs from "@/components/base/tabs/mTabs";

export default {
  name: "knowledgeBase-courseEdit",
  components: {
    mTabs,
    BaseDetailView,
    mAutocomplete,
    BasePanel,
    records,
    // MCombobox,
    // SelectEmployees,
    participants,
    LessonEdit,
    ReportActivator,
    BaseDatePicker,
    DocumentSelectEmlpoyees,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  props: {
    isEmployee: {
      type: Boolean,
      default: false,
    },
    courseType: {
      type: String,
      default: "all",
      validator: function (value) {
        return ["all", "base"].includes(value);
      },
    },
    employeeId: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      apiService:
        this.courseType === "base"
          ? KnowledgeBaseService.courseTemplate
          : !this.isEmployee
          ? KnowledgeBaseService.course
          : KnowledgeBaseService.сourseEmployee,
      loading: true,
      dataSource: KnowledgeBase.course(),
      getDefaultObject: KnowledgeBase.course,
      valid: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      employees: [],
      categories: [],
      tab: 0,
      getObjectText: (obj) => obj.Name,
      dialogEdit: false,
      currentLesson: KnowledgeBase.lesson(),
      allowSaveForge: true,
    };
  },
  computed: {
    Permissions() {
      return Permissions;
    },
    readonly() {
      return (
        this.isEmployee ||
        (!(
          this.isAuthor &&
          this.hasPermission(Permissions.KnowledgeBaseCourseEdit)
        ) &&
          !(
            this.isCurator &&
            this.hasPermission(Permissions.KnowledgeBaseCourseEdit)
          ) &&
          !this.hasPermission(Permissions.KnowledgeBaseAllCourseEdit))
      );
    },
    isAuthor() {
      return this.dataSource.Authors.some((e) => {
        return this.$store.getters.GET_USER?.Employee.Id === e.Id;
      });
    },
    isCurator() {
      return this.dataSource.Curators?.some((e) => {
        return this.$store.getters.GET_USER?.Employee.Id === e.Id;
      });
    },
    courseParticipants() {
      return this.dataSource.Employees?.map((a) => a.Employee);
    },
    CourseStatus: () => CourseStatus,
  },
  async mounted() {
    await this.init();
    this.employees = await this.LOAD_EMPLOYEES();
    this.categories = await this.LOAD_COURSE_CATEGORIES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    ...mapActions("loaderDataSources", ["LOAD_COURSE_CATEGORIES"]),
    CopyDataNormalize(data) {
      if (data?.Employees?.length) {
        for (let i = 0; i < data.Employees.length; i++) {
          data.Employees[i].Completed = 0;
          data.Employees[i].Status = 1;
        }
        data.Period.DateIn = DataHelper.toServerDateTime(DataHelper.today());
        data.Period.DateOut = null;
        data.Status = 1;
      }
      return data;
    },
    applyCourse() {
      this.$router.push({
        name: "KnowledgeBaseCourseEdit",
        params: { objectId: this.dataSource.Id },
        query: {
          type: "create",
        },
      });
    },

    copyNewLessons(items) {
      // Материалы
      for (let i = 0; i < items.records.length; i++) {
        this.dataSource.Records.push(items.records[i]);
      }

      // Уроки
      for (let i = 0; i < items.lessons.length; i++) {
        items.lessons[i].IsLesson = true;
        items.lessons[i].Children = items.lessons[i].Records;
        delete items.lessons[i].Records;
        this.dataSource.Records.push(items.lessons[i]);
      }

      this.dataSource = this.DataFromServerNormalize(this.dataSource);
    },

    async init() {
      if (this.isNested) {
        try {
          this.loading = true;
          const res = await this.apiService.show(
            this.objectId,
            this.employeeId
          );
          this.dataSource = this.DataFromServerNormalize(res.data);
          await this.updateDataView();
          this.originObject = this.getActualDataSources(this.dataSource);
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      } else this.baseInit();

      if (this.type === "create" && this.$route.params?.objectId > 0) {
        const base = (
          await KnowledgeBaseService.courseTemplate.show(
            this.$route.params?.objectId
          )
        ).data;
        base.Id = -Math.round(Math.random() * 100000);
        base.TemplateId = this.$route.params?.objectId;
        for (const i in base) {
          this.$set(this.dataSource, i, base[i]);
        }
        this.dataSource = this.DataFromServerNormalize(this.dataSource);
      }
    },

    getParent(e) {
      let parent;
      if (!e.lesson) {
        parent = this.dataSource.Records;
      } else {
        const inx = this.dataSource.Records.findIndex(
          (l) => l.Id === e.lesson.Id
        );
        parent = this.dataSource.Records[inx].Children;
      }
      return parent;
    },

    addMaterial(e) {
      let item = null;
      if (!e.lesson) {
        item = {
          ...KnowledgeBase.record(),
          Type: e.type,
          lessonId: -1,
        };
      } else {
        item = {
          ...KnowledgeBase.record(),
          Id: Math.round(Math.random() * 100000),
          Type: e.type,
          lesson: e.lesson,
          lessonId: e.lesson.Id,
        };
      }

      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "knowledgeBase/MaterialEdit",
        callback: async () => {
          const obj = this.$store.getters["frame/GET_DATA"]().object;

          this.getParent(e).push(obj);
          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });

          // saveCourse
          await this.save();
          if (e.type === 0) {
            const p = this.getParent(e);
            const item = p[p.length - 1];
            this.$router.push({
              name: "KnowledgeBaseTestEdit",
              params: {
                objectId: item.Id,
              },
            });
          }
        },
        params: {
          _objectId: item.Id,
          _type: "edit",
          _object: item,
          closeDestoroy: true,
          simple: true,
        },
      });
    },
    addLesson() {
      this.currentLesson = KnowledgeBase.lesson();
      this.currentLesson.Num = this.calculateNum();

      this.currentLesson.IsLesson = true;
      this.dialogEdit = true;
    },
    editLesson(id) {
      const lesson = this.dataSource.Records.find((l) => l.Id === id);
      if (!lesson) return;
      this.currentLesson = lesson;
      this.dialogEdit = true;
    },
    deleteLesson(id) {
      this.dataSource.Records = this.dataSource.Records.filter(
        (r) => r.Id !== id
      );
    },
    deleteMaterial(id) {
      this.dataSource.Records = this.dataSource.Records.filter(
        (r) => r.Id !== id
      );

      for (let i = 0; i < this.dataSource.Records.length; i++) {
        if (!this.dataSource.Records[i].IsLesson) continue;
        const delIndex = this.dataSource.Records[i].Children.findIndex(
          (e) => e.Id === id
        );
        if (delIndex > -1) {
          this.dataSource.Records[i].Children.splice(delIndex, 1);
          break;
        }
      }
    },
    calculateNum() {
      const lessons = this.dataSource.Records.filter((e) => e.IsLesson);
      if (!lessons.length) return 1;
      return lessons[lessons.length - 1].Num + 1;
    },
    DataFromServerNormalize(dataSource) {
      return dataSource;
    },
    getActualDataSources(data) {
      const dataSource = this.baseGetActualDataSources(data);

      return dataSource;
    },

    input(participants) {
      const array = [];
      for (const participant of participants) {
        array.push({ ...KnowledgeBase.participant(), Employee: participant });
      }
      this.dataSource.Employees = array;
    },

    async saveLesson(value) {
      this.dialogEdit = false;
      if (!value) return;

      const index = this.dataSource.Records.findIndex(
        (item) => item.Id === value.Id
      );
      if (index !== -1) this.$set(this.dataSource.Records, index, value);
      else this.dataSource.Records.push(value);
      await this.save();
    },
    async save() {
      if (this.courseType !== "base" && !this.dataSource.IsLimited) {
        this.dataSource.Period.DateIn =
          moment().format("DD.MM.YYYY") + " 00:00:00";
        this.dataSource.Period.DateOut = null;
      }
      if (this.dataSource.Employees)
        this.dataSource.Employees = this.uniqEmployees(
          this.dataSource.Employees
        );
      await this.baseSave();
    },
    uniqEmployees(array) {
      return array.reduce((o, i) => {
        if (!o.find((v) => v.Employee.Id === i.Employee.Id)) {
          o.push(i);
        }
        return o;
      }, []);
    },
  },
};
</script>
<style lang="scss">
#knowledge-base__courses-edit {
  .v-row-group__header
    + tr:not(.v-row-group__header):not(.base-table-full__add) {
    display: none;
  }
}
</style>
